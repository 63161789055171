@media (min-width: 0px) {
  div.cdk-global-overlay-wrapper div.cdk-overlay-pane {
    display: flex;
    justify-content: center;
    max-width: 90vw !important;
  }
}

.mat-dialog-container {
  border: 1px solid #7b7b7b;
  border-radius: 0.6rem !important;
  box-shadow: none;
}

.error-notification,
.notification-modal,
.password-reset,
.admin-add-order,
.add-edit-user-component,
.add-new-user {
  .mat-dialog-container {
    width: 51rem;
    @media (max-width: 500px) {
      width: 100%;
    }
  }
}

.need-help,
.password-reset,
.add-new-product,
.add-new-case-code {
  .mat-dialog-container {
    width: 57rem;
    @media (max-width: 560px) {
      width: 100%;
    }
  }
}

.admin-edit-order {
  .mat-dialog-container {
    max-height: 90vh;
    width: 88rem;
  }

  .mat-dialog-content {
  }

  .mat-dialog-container {
    padding: 3rem 5rem 3rem 3rem;
    box-shadow: none;

    @media (max-width: 450px) {
      padding: 3rem 1.5rem 3rem 1.5rem;
    }
  }
}

.add-gtin-first {
  .mat-dialog-container {
    max-height: 90vh;
    width: 67rem;
    padding: 3rem;

    @media (max-width: 450px) {
      padding: 3rem 1.5rem 3rem 1.5rem;
    }
  }
}

.clean-product,
.add-new-user,
.add-payment-information,
.edit-compant-information,
.product-renewal,
.gtin-dialog-brands,
.need-help,
.password-reset,
.add-new-product,
.add-new-case-code,
.add-edit-user-component,
.admin-add-order {
  .mat-dialog-content {
    max-height: 80vh;
  }

  .mat-dialog-container {
    padding: 3rem;
    box-shadow: none;

    @media (max-width: 730px) {
      overflow: visible;
    }
  }
}

.clean-product,
.add-new-user,
.add-payment-information,
.edit-compant-information,
.product-renewal,
.gtin-dialog-brands {
  .mat-dialog-container {
    width: 510px;
  }
}

.edit-compant-information,
.product-renewal {
  .mat-dialog-container {
    width: 1060px;
  }
}

.gtin-certview-panel {
  width: 90vw;
  height: 90vh;
  @media (max-width: 730px) {
    .mat-dialog-container {
      padding: 5px;
    }
  }
}
