/* You can add global styles to this file, and also import other style files */
@import 'styles/variables';
// Imports functions, variables, and mixins that are needed by other Bootstrap files
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
// Import Reboot
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/grid'; // add the grid
@import '~bootstrap/scss/utilities'; // add css utilities
@import 'styles/normalize';
@import 'styles/reset';
@import 'styles/null';
@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~styles/modals'; // modals styles

//<Fonts>
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');
@import '~@ng-select/ng-select/themes/default.theme.css';
$fontfamily: 'Roboto', sans-serif;

body,
html {
  font-size: 10px;
}
body {
  position: relative;
}
gtin-select {
  position: relative !important;
}
body {
  h1,
  h2,
  h3 {
    font-family: $fontfamily !important;
  }

  &.mat-typography {
    font-family: $fontfamily !important;
  }

  color: #000000;
  font-family: $fontfamily;
}

.mat-h3,
.mat-subheading-2,
.mat-typography h3 {
  margin: 0;
}

.mat-h2,
.mat-subheading-2,
.mat-typography h2 {
  margin: 0;
}

.grecaptcha-badge {
  visibility: hidden !important;
  opacity: 0 !important;
  display: none !important;
}

.wrapper {
  width: 100%;
}

.content-wrapper {
  display: flex;
  min-height: 100%;
}

.main {
  width: 100%;
  // height: 100%;
  // overflow-x: hidden;
  max-height: 100%;
  overflow: auto;
}

.cdk-overlay-container {
  z-index: 999 !important;
}

.container {
  max-width: 139rem;
  margin: 0 auto;
  height: 100%;
  width: 100%;
  padding: 0 3.4rem;

  @media (max-width: 768px) {
    padding: 0rem 1.5rem;
  }
}

.container-big {
  max-width: none;
  margin: 0 auto;
  height: 100%;
  width: 100%;
  padding: 0 3.4rem;

  @media (max-width: 768px) {
    padding: 0rem 1.5rem;
  }
}

.container-header {
  max-width: none;
  margin: 0 auto;
  height: 100%;
  width: 100%;
  padding: 0 1.2rem;
}

.container-aside {
  max-width: 30rem;
  margin: 0 auto;
  height: 100%;
  width: 100%;
  padding: 0 0.7rem 0 3.3rem;
}

button {
  &.btn.blue,
  &.btn.orange,
  &.btn.gray {
    &:hover {
      color: #fff;
    }
  }
}
.btn {
  font-weight: 900;
  font-size: 1.8rem;
  line-height: 2.2rem;
  border-radius: 0.6rem;
  color: #ffffff;
  border: none;
  padding: 0.5rem 2.8rem;

  &.big {
    padding: 2rem;
  }

  &.orange {
    white-space: nowrap;
    background: #f7603e;
  }

  &.blue {
    white-space: nowrap;
    background: #049ae0;
  }

  &.gray {
    white-space: nowrap;
    background: #7b7b7b;
  }
}

a {
  transition: all 0.3s ease;
  color: $orange;

  &.forgot-pass:hover,
  &.forgot-pass:focus {
    color: #f7603ec1;
  }
}

.form-control {
  position: relative;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: #7b7b7b;
  border: 1px solid #7b7b7b;
  border-radius: 0.6rem;
  padding: 0.3rem 1rem;
  margin-top: 1rem;
}

button.orange:hover,
button.orange:focus {
  background-color: #f7603ec1;
}

button.blue:hover,
button.blue:focus {
  background-color: #358dd1b1;
}

.form-group {
  position: relative;
  margin-bottom: 0;
  padding-bottom: 2rem;
}

.error-message {
  position: absolute;
  color: #de5959;
  font-size: 14px;
  bottom: 0;
  font-weight: 400;
}

button.btn-reset {
  white-space: nowrap;
  background-color: transparent;
  border: transparent;
  outline: transparent;
  &:focus {
    box-shadow: none;
  }
  &:hover {
    box-shadow: none;
  }
}
button {
  &:focus {
    box-shadow: none;
  }
  &:hover {
    box-shadow: none;
  }
}
.label-blue {
  width: 100%;
  color: #049ae0;
  font-size: 1.8rem;
  margin-bottom: 0;
}

.nowrap {
  white-space: nowrap;
}

html,
body {
  height: 100%;
}

body {
  position: relative;
  #init-loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    position: fixed;
    width: 100vw;
    height: 100vh;
    overflow-y: hidden;

    & > div {
      font-size: 10px;
      margin: 50px auto;
      text-indent: -9999em;
      width: 11em;
      height: 11em;
      border-radius: 50%;
      background: #0e1845;
      background: -moz-linear-gradient(
        left,
        #0e1845 10%,
        rgba(90, 53, 53, 0) 42%
      );
      background: -webkit-linear-gradient(
        left,
        #0e1845 10%,
        rgba(90, 53, 53, 0) 42%
      );
      background: -o-linear-gradient(
        left,
        #0e1845 10%,
        rgba(90, 53, 53, 0) 42%
      );
      background: -ms-linear-gradient(
        left,
        #0e1845 10%,
        rgba(90, 53, 53, 0) 42%
      );
      background: linear-gradient(
        to right,
        #0e1845 10%,
        rgba(90, 53, 53, 0) 42%
      );
      position: relative;
      -webkit-animation: load3 1.4s infinite linear;
      animation: load3 1.4s infinite linear;
      -webkit-transform: translateZ(0);
      -ms-transform: translateZ(0);
      transform: translateZ(0);
    }

    & > div:before {
      width: 50%;
      height: 50%;
      background: #0e1845;
      border-radius: 100% 0 0 0;
      position: absolute;
      top: 0;
      left: 0;
      content: '';
    }

    & > div::after {
      background: #fcfcfc;
      width: 75%;
      height: 75%;
      border-radius: 50%;
      content: '';
      margin: auto;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }

  @-webkit-keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}
.margin-top {
  display: block;
  margin-top: 1rem;
}
.label-select {
  margin-bottom: 1rem;
}
a.btn.orange,
button.btn.orange,
label.btn.orange {
  &:focus,
  &:hover {
    background-color: #f7603ec1;
    color: #fff;
  }
}
a.btn.blue,
button.btn.blue,
label.btn.blue {
  &:focus,
  &:hover {
    background-color: #45c4ff;
    color: #fff;
  }
}
.form-check-input:focus {
  border: 1px solid rgba(0, 0, 0, 0.25);
}
.form-control {
  color: #000;
}

.mat-slide-toggle {
  &.mat-checked {
    .mat-slide-toggle-bar {
      background-color: rgba(4, 154, 224, 0.54);
    }
    .mat-slide-toggle-thumb {
      background-color: #049ae0;
    }
  }
}

.dataEnteredCount {
  color: white;
  padding: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 2.4rem;
  height: 2.4rem;
  font-size: 1.2rem;
  line-height: 1rem;
  font-weight: 900;
  margin-left: 1rem;
  border-radius: 2rem;
  border: thin solid white;
  background-color: #a200cb;
  font-family: 'Lato', sans-serif;
  text-decoration: none !important;
}
