* {
  box-sizing: border-box;
}

input::-ms-clear {
  display: none;
}

button,
input,
select,
div {
  outline: none;
}

a,
[routerLink] {
  text-decoration: none;
}

button {
  cursor: pointer;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

hr {
  margin: 0;
  border: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
