*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

:focus,
:active {
  outline: none;
}

a:focus,
a:active {
  outline: none;
}

a,
a:visited,
a:hover {
  text-decoration: none;
}

html,
body {
  height: 100%;
  min-height: 100%;
  // min-width: 130rem;
  margin: 0;
}

body {
  line-height: 1;
  font-family: 'Lato', sans-serif;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

input,
button,
textarea {
  font-family: 'Lato', sans-serif;
}

button {
  cursor: pointer;
}

ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
}
