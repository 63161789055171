$link-color: #3f51b5;
$link-hover-color: currentColor;
$link-hover-decoration: none;
$orange: #f7603e;
$grid-breakpoints: (
  xs: 0,
  // handset portrait (small, medium, large) | handset landscape (small)
  sm: 600px,
  // handset landscape (medium, large) | tablet portrait(small, large)
  md: 960px,
  //  tablet landscape (small, large)
  lg: 1280px,
  // laptops and desktops
  xl: 1600px // large desktops,,,
);

$container-max-widths: (
  sm: 600px,
  md: 960px,
  lg: 1280px,
  xl: 1600px,
);

@mixin respond($breakpoint) {
  @if $breakpoint==2xl-screen {
    @media only screen and (min-width: 1200px) {
      @content;
    } //1200
  }

  @if $breakpoint==xl-screen {
    @media only screen and (max-width: 1399.98px) {
      @content;
    } //1400
  }

  @if $breakpoint==lg-screen {
    @media only screen and (max-width: 1199.98px) {
      @content;
    } //1200
  }

  @if $breakpoint==m-screen {
    @media only screen and (max-width: 991.98px) {
      @content;
    } //992
  }

  @if $breakpoint==s-screen {
    @media only screen and (max-width: 767.98px) {
      @content;
    } //768
  }

  @if $breakpoint==es-screen {
    @media only screen and (max-width: 575.98px) {
      @content;
    } //576
  }

  @if $breakpoint==iphone_6 {
    @media only screen and (max-width: 375.98px) {
      @content;
    } //376
  }

  @if $breakpoint==iphone_5 {
    @media only screen and (max-width: 320.98px) {
      @content;
    } //321
  }
}
